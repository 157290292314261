import React from "react";
import Roadmapimg1 from '../../../assets/images/roadmap-icon1.png'
import Roadmapimg2 from '../../../assets/images/roadmap-icon2.png'
import Roadmapimg3 from '../../../assets/images/roadmap-icon3.png'
import Roadmapimg4 from '../../../assets/images/roadmap-icon4.png'
import Roadmapimg5 from '../../../assets/images/roadmap-icon5.png'



const HowItWorks = () => {
  return (
    <>
        <div className="ecosystem roadmapOut">
            <div className="container">
                <h2 className="ecoHd">Roadmap</h2>
                <div className="roadmapOuter">
                  <div className="roadbx1 roadmapInn">
                    <img src={Roadmapimg1} alt="" />
                    <h2>Q2 2023</h2>
                    <div className="roadmapCont">
                      <span>Launch of GameSphere Teaser</span>
                      <span>Launch of Website With Wallet</span>
                      <span>Initiating the Fund Raising (Private Sale) - Seed</span>
                    </div>
                  </div>
                  <div className="roadbx2 roadmapInn">
                    <img src={Roadmapimg2} alt="" />
                    <h2>Q3 2023</h2>
                    <div className="roadmapCont">
                      <span>Launch of Foundation ($GSP)</span>
                      <span>Launch of NFT Marketplace</span>
                      <span>Partners/ Game Studios Onboarding</span>
                    </div>
                  </div>
                  <div className="roadbx1 roadmapInn">
                    <img src={Roadmapimg3} alt="" />
                    <h2>Q4 2023</h2>
                    <div className="roadmapCont">
                      <span>Public Sale</span>
                      <span>Listing of $GSP on Exchanges</span>
                      <span>Game Studios Integration (Open API’s/ SDK’s)</span>
                    </div>
                  </div>
                  <div className="roadbx2 roadmapInn">
                    <img src={Roadmapimg4} alt="" />
                    <h2>Q2 2024</h2>
                    <div className="roadmapCont">
                      <span>$GSP EcoSystem reaches 50 Active Games</span>
                      <span>Active User base of 500K +</span>
                      <span>Launch of Gaming Grants</span>
                    </div>
                  </div>
                  <div className="roadbx1 roadmapInn">
                    <img src={Roadmapimg5} alt="" />
                    <h2>Q2 2024</h2>
                    <div className="roadmapCont">
                      <span>Active User Base of 1Million +</span>
                      <span>Launch of GameSphere Meta</span>
                      <span>Launch of Charity Foundation</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default HowItWorks