import React, { useState, useEffect } from "react";
import { Button, Input } from 'reactstrap';
import Header from '../../common-components/header/header'
import marketGraph1 from "../../../assets/images/marketgraph1.png"
import marketGraph2 from "../../../assets/images/marketgraph2.png"
import marketGraph3 from "../../../assets/images/marketgraph3.png"
import Hotsale from "../../../assets/images/hot-sale.png"
import Token from "../../../assets/images/Token.png"
import GroupIcon from "../../../assets/images/Group1.png"
import RecentListing from "./recent-listing"
import FootLogo from "../../../assets/images/footer-logo.png"
import SocialIcon1 from "../../../assets/images/social-icon1.png"
import SocialIcon2 from "../../../assets/images/social-icon2.png"
import SocialIcon3 from "../../../assets/images/social-icon3.png"
import SocialIcon4 from "../../../assets/images/social-icon4.png"
import SocialIcon5 from "../../../assets/images/social-icon5.png"

import "./marketplace.scss"


const Marketplace = () => {

  const [year, setYear] = useState('')

  useEffect(() => {
    const d = new Date();
    let getYear = d.getFullYear();
    setYear(getYear)
  }, [])
    
    return (
        <>
            <div className='markeplaceBg'>
                <div className='container'>
                    <Header />
                    <div className='marketplace_Inn'>
                        <div className="marketplaceHd">
                            <h2>marketplace</h2>
                        </div>
                        <div className="overallstatus">
                            <div className="statusHdOuter">
                                <div className="overallHd">Overall stats</div>
                                <div className="statusDays">
                                    <span className="active">24H</span>
                                    <span>7D</span>
                                    <span>30D</span>
                                </div>
                            </div>
                            <div className="statusOuter">
                                <div className="statusBx">
                                    <div className="statusHd">
                                        <i><img src={Hotsale} alt="" /></i>
                                        <span>Total Sales</span>
                                        <strong>4,829</strong>
                                    </div>
                                    <div className="statusGraph">
                                        <img src={marketGraph1} alt="" />
                                    </div>
                                </div>
                                <div className="statusBx">
                                    <div className="statusHd">
                                        <i><img src={Token} alt="" /></i>
                                        <span>Total volume</span>
                                        <strong>52.12</strong>
                                    </div>
                                    <div className="statusGraph">
                                        <img src={marketGraph2} alt="" />
                                    </div>
                                </div>
                                <div className="statusBx">
                                    <div className="statusHd">
                                        <i><img src={GroupIcon} alt="" /></i>
                                        <span>Total NFT’s Sold on GameSphere</span>
                                        <strong>4,520</strong>
                                    </div>
                                    <div className="statusGraph">
                                        <img src={marketGraph3} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="recentListing">
                            <div className="recentListingHd">
                                Recent Listings
                            </div>
                            <RecentListing />
                        </div>
                        <div className="recentListing">
                            <div className="recentListingHd">
                                All NFTs
                            </div>
                            <RecentListing />
                        </div>
                        <div className="homeFooter mpFoot">
                            <i><img src={FootLogo} alt="" /></i>
                            <span>GAMESPHERE TECHNOLOGY LLC</span>
                            <a href="https://www.facebook.com/gamesphere.io" target="_blank"><img src={SocialIcon1} alt="" /></a>
                            <a href="https://t.me/gamespherenews" target="_blank"><img src={SocialIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/gamesphere_io/" target="_blank"><img src={SocialIcon3} alt="" /></a>
                            <a href="https://discord.com/invite/27WgyZKN" target="_blank"><img src={SocialIcon4} alt="" /></a>
                            <a href="https://www.linkedin.com/company/gamesphere-io/" target="_blank"><img src={SocialIcon5} alt="" /></a>
                            <span>Gamesphere technology llc {year} &copy; All right reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Marketplace

