import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import Header from "../../common-components/header/header";
import TokanImg from "../../../assets/images/Token_icon.png";
import GameHdImg from "../../../assets/images/Game-sphere-world.png"
import shapImg from "../../../assets/images/shape1.png"
import PlayIcon from "../../../assets/images/play-icon.png"
import FootLogo from "../../../assets/images/footer-logo.png"
import SocialIcon1 from "../../../assets/images/social-icon1.png"
import SocialIcon2 from "../../../assets/images/social-icon2.png"
import SocialIcon3 from "../../../assets/images/social-icon3.png"
import SocialIcon4 from "../../../assets/images/social-icon4.png"
import SocialIcon5 from "../../../assets/images/social-icon5.png"

import { Link, useNavigate } from "react-router-dom";
import "./home.scss";
import Ecosystem from './ecosystem'
import KeyFeatures from './key-features'
import HowItWorks from "./how-it-works";
import Mission from "./mission";
import About from "./about"
import Roadmap from "./roadmap"
import HowList from "./howlist"
import Partner from "./partners"

import { Player } from 'video-react';

const Home = () => {
  const [year, setYear] = useState('')
  const navigate = useNavigate()
  const userToken = localStorage.getItem('userToken');


  const logOut = () => {
    localStorage.removeItem('userToken')
    window.location.assign("./");

  }


  useEffect(() => {
    const d = new Date();
    let getYear = d.getFullYear();
    setYear(getYear)
  }, [])

  return (
    <>
      <div className="mainBg">
        <div className="container">
          <Header />
          <div className="MidSecBx">
            <div className="homeLeft">
              <h2>enter the game sphere</h2>
              <p>
                Revolutionizing the mass adoption of traditional games to build
                a truly decentralized Web3.0 gaming ecosystem and adequate
                funding for new and upcoming gaming projects.
              </p>
              <Link to="/">Learn More</Link>
            </div>
            <div className="homeRight">
              <div className="metamaskBx">
                <i>
                  <img src={TokanImg} alt="" />
                </i>
                {!userToken ? <Button>  <Link to="/login">Connect Wallet</Link></Button> : <Button>  <Link onClick={logOut}>Disconnect Wallet</Link></Button>}


              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="midBg">
        <div className="midBgInn">
          <div className="gameWorld">
            <div className="gamevideoInn">
              <div className="gamevidBx">
                <div className="gameHd"><img src={GameHdImg} alt="" /></div>
                {/* <div className="shape1"><img src={shapImg} alt="" /></div>
                <div className="shape2"><img src={shapImg} alt="" /></div> */}
                <div className="gridVideo">
                  <Player>
                    <source src="https://flexsin.org/HTML/Game-Sphere/game-sphere.mp4" />
                  </Player>
                  <Button><img src={PlayIcon} alt='' /></Button>
                </div>
              </div>
            </div>
          </div>

          <Ecosystem />
          <KeyFeatures />

          <div className="fillShapeBx">
            <div className="container">
              <div className="innerCont">
                Gamers will have the option to earn $GSP while they play and
                use the platform, unlocking access to games, exclusive drops,
                and community involvement within our ecosystem of Games. $GSP
                will be able to seamlessly transfer earnings between games,
                replacing manual transfer.
              </div>
            </div>
          </div>

          <HowItWorks />
          <Mission />
          <About />
          <Roadmap />
          <HowList />
          <Partner />
          <div className="joinOuter">
            <div className="container">
              <div className="joinBx">
                <h2>Join us and enter<br />the Gamesphere</h2>
                <Button className="joinComBtn"><a href="https://linktr.ee/gamesphere" target="_blank">Join The Community</a></Button>
              </div>
            </div>
            <div className="homeFooter">
              <i><img src={FootLogo} alt="" /></i>
              <span>GAMESPHERE TECHNOLOGY LLC</span>
              <a href="https://www.facebook.com/gamesphere.io" target="_blank"><img src={SocialIcon1} alt="" /></a>
              <a href="https://t.me/gamespherenews" target="_blank"><img src={SocialIcon2} alt="" /></a>
              <a href="https://www.instagram.com/gamesphere_io/" target="_blank"><img src={SocialIcon3} alt="" /></a>
              <a href="https://discord.com/invite/27WgyZKN" target="_blank"><img src={SocialIcon4} alt="" /></a>
              <a href="https://www.linkedin.com/company/gamesphere-io/" target="_blank"><img src={SocialIcon5} alt="" /></a>
              <span>Gamesphere technology llc {year} &copy; All right reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
